import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'



ReactDOM.render( //rendizamos la aplicacion en pantalla
  <div>
      <App />
  </div>,
  document.getElementById('root') //se pone la pagina en la aplicacion react
);